import HttpService from '../HttpService';

export default class OdooService {
    constructor() {
      this._httpService = new HttpService('/odoo');
    }

    async FindPortalAccess(contractId) {
      return await this._httpService.get(`/portal-access/bySubContract?contractId=${contractId}`);
    }
 };
